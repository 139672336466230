import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

const Sizing = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: ${({ ratio }) => (ratio ? ratio * 100 + '%' : '100%')};
`
const Box = ({ children }) => (
  <Sizing>
    <div
      css={css`
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      `}
    >
      {children}
    </div>
  </Sizing>
)

export default Box
