import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { StaticQuery, graphql } from 'gatsby'
import Box from '../box'
import { mq } from '../utils'

const AwardList = styled.ul`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
`

const AwardItem = styled.li`
  position: relative;
  flex: 0 1 33%;
  padding: 2.5rem;
  /* ie 11 bug, flex-basis doesn't account for border-box padding */
  -ms-flex-preferred-size: calc(33% - 5rem);

  ${mq[0]} {
    flex: 0 1 100%;
    padding: 1.5rem;
    -ms-flex-preferred-size: calc(100% - 3rem);
  }
`

const AwardName = styled.span`
  width: 100%;
  height: 100%;
  font-size: 1.5rem;
  line-height: 1.2;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
`

const AwardAmount = styled.span`
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 1.5rem;
  line-height: 1.1;
  color: #fff;
`

const AwardTitle = styled.p`
  padding: 1rem 0;
`

const Award = ({ award }) => {
  const { name, logo, amount } = award
  return (
    <AwardItem>
      <Box>
        {logo && logo.childImageSharp ? (
          <img
            css={css`
              width: 100%;
              height: auto;
            `}
            src={logo.childImageSharp.fixed.src}
            alt={name}
          />
        ) : (
          <AwardName>{name}</AwardName>
        )}
        <AwardAmount>&times; {amount}</AwardAmount>
      </Box>
      <AwardTitle>{name}</AwardTitle>
    </AwardItem>
  )
}

const Awards = () => (
  <StaticQuery
    query={graphql`
      query {
        company {
          awards {
            amount
            ...ImageAndName
          }
        }
      }
    `}
    render={data => (
      <AwardList>
        {data.company.awards.map((award, i) => (
          <Award key={i} award={award} />
        ))}
      </AwardList>
    )}
  />
)

export default Awards
