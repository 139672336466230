import React from 'react'
import styled from '@emotion/styled'
import Divider from '../divider'
import { Header } from '../typography'
import { fadeIn, mq } from '../utils'

const SectionLayout = styled.section`
  flex: 0 1 auto;
  position: relative;
  background: #000;
  color: #fff;
  z-index: 800;
  width: ${({ width }) => width || '80%'};
  max-width: 40rem;
  margin: 0 auto 4rem;
  text-align: center;
  animation: ${fadeIn} 0.3s ease-out;
  animation-fill-mode: both;

  & > hr {
    margin-top: 3rem;
  }

  &:last-of-type {
    margin-bottom: 8rem;
  }

  ${mq[0]} {
    width: 80%;
  }
`

export const Section = ({ header, width, children }) => (
  <SectionLayout width={width}>
    <Header bottom="3rem">{header}</Header>
    {children}
    <Divider />
  </SectionLayout>
)

export const Sections = styled.main`
  position: relative;
  display: flex;
  width: 100vw;
  margin-top: 12rem;
  flex-direction: column;

  ${mq[0]} {
    margin-top: 7rem;
  }
`
