import React from 'react'
import { css } from '@emotion/core'

const Footer = () => (
  <footer
    css={css`
      width: 100%;
      text-align: center;
      font-size: 0.75rem;
      padding: 0.5rem;
      opacity: 0.6;
    `}
  >
    © WONDERBOYS 2018 | All Rights Reserved.
  </footer>
)

export default Footer
