import React from 'react'
import { graphql } from 'gatsby'
import { css } from '@emotion/core'
import styled from '@emotion/styled'

import SEO from '../components/seo'
import Layout from '../components/layout'

import { Nav } from '../components/nav'

import Clients from '../components/company/clients'
import Awards from '../components/company/awards'
import { Sections, Section } from '../components/company/sections'
import CtaButton from '../components/company/cta'
import { Button } from '../components/button'
import Footer from '../components/footer'

const About = ({ content, size }) => (
  <div
    css={css`
      font-size: ${({ size }) => size || '1rem'};
      white-space: pre-wrap;
      margin-bottom: 2rem;
      line-height: 1.5;

      small {
        font-size: 0.9em;
        opacity: 0.8;
      }
    `}
    dangerouslySetInnerHTML={{
      __html: content,
    }}
  />
)

const LinkButton = styled(Button)`
  & + & {
    margin-left: 0.5rem;
  }
`.withComponent('a')

const CompanyPage = ({ data }) => {
  const { title, aboutKR, aboutEN, cred_PDF, cred_PPT } = data.company
  return (
    <>
      <SEO name="Company" />
      <Nav right={<CtaButton size="1">Contact Us</CtaButton>} />
      <Sections>
        <Section header={title} width="40%">
          <About content={aboutKR} />
          <About content={aboutEN} size="1.25" />
        </Section>
        <Section header="Clients">
          <Clients />
        </Section>
        <Section header="Awards">
          <Awards />
        </Section>
        <Section header="Credentials">
          <p>View our credentials.</p>
          <div>
            <LinkButton size="0.75" href={cred_PDF.link}>
              .pdf
            </LinkButton>
            <LinkButton size="0.75" href={cred_PPT.link}>
              .ppt
            </LinkButton>
          </div>
        </Section>
      </Sections>
      <div
        css={css`
          text-align: center;
          margin-bottom: 10rem;
        `}
      >
        <CtaButton>Contact Us</CtaButton>
      </div>
      <Footer />
    </>
  )
}

export const query = graphql`
  query {
    company {
      title
      aboutKR
      aboutEN
      cred_PDF {
        link
      }
      cred_PPT {
        link
      }
    }
  }
`

export default CompanyPage
